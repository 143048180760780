export default function() {
    const main = $('main');

    if(window.innerWidth > 768) {
        setFooterHeight();

        $(window).resize(function() {
            setFooterHeight();
        });
    }

    function setFooterHeight() {
        const footerHeight = $('footer').innerHeight();
        main.css("margin-bottom", footerHeight);
    }

    if(window.innerWidth < 1024) {
        $('.mobile-trigger').on("click", function() {
            $(this).toggleClass('open');
            //setFooterHeight();
        });
    }
}
