import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default function() {
    $(".play-video").on("click", function() {
        const videoURL = $(this).attr("data-video-url");

        const options = {
            dataSource: [
                {
                    html: '<div class="popup-wrapper"><div class="iframe-video-popup"><iframe id="iframe-video" src="' + videoURL + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen  style="position:absolute;top:0;left:0;width:100%;height:-webkit-fill-available;"></iframe></div></div>'
                }
            ],
            showHideAnimationType: 'none'
        };

        const pswp = new PhotoSwipe(options);
        pswp.init();
    });
}
