import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default function() {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '#project-gallery',
        children: 'a',
        pswpModule: PhotoSwipe
    });
    lightbox.init();
}
