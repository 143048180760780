export default function() {
    if($('.scroll-down')) {
        $('.scroll-down').on("click", function() {
            //console.log($(this).closest('.homepage-header').next());

            let nextSection = $(this).closest('section:first-of-type').next(),
                main = $('html, body');

            main.animate({
                scrollTop: nextSection.offset().top
            }, 700);
        });
    }

    if($('.watch-video')) {
        $('.watch-video').on("click", function() {

            const player = new Vimeo.Player(document.querySelector('iframe'));
            player.requestFullscreen();
        });
    }
}


