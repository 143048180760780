export default function() {
    if ($(window).width() > 768) {
        $('.projects-overview .project-item').hover(function () {
            $('.projects-overview .hover').addClass('active')
        }, function () {
            $('.projects-overview .hover').removeClass('active')
            $('.projects-overview .hover').removeAttr('style');
        });

        $('.projects-overview .project-item').mousemove(function (e) {
            let top = e.clientY;
            let left = e.clientX;

            $('.projects-overview .hover').css('top', top);
            $('.projects-overview .hover').css('left', left);
        });
    }}
