export default function() {
    if ($(window).width() > 768) {
        $('.fullscreen-ctas .fullscreen-cta-item').hover(function () {
            $('.cases-slider .hover').addClass('active')
        }, function () {
            $('.fullscreen-ctas .hover').removeClass('active')
            $('.fullscreen-ctas .hover').removeAttr('style');
        });

        $('.fullscreen-ctas .fullscreen-cta-item').mousemove(function (e) {
            let top = e.clientY;
            let left = e.clientX;

            $('.fullscreen-ctas .hover').css('top', top);
            $('.fullscreen-ctas .hover').css('left', left);
        });
    }
}
