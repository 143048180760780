import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export default function() {
    if(document.querySelector('.two-sliders') === null) {
        return;
    }

    const swiperProductsLeft = new Swiper('.product-swiper', {
        slidesPerView: 1,
        spaceBetween: 16,
        freeMode: true,
        loop: true,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: ".review-swiper-button-next",
            prevEl: ".review-swiper-button-prev",
        },
        breakpoints: {
            1500: {
                slidesPerView: 2.7721,
            },
            1024: {
                slidesPerView: 1.7721,
            },
            768: {
                slidesPerView: 1.5,
            },
        }
    });

    if ($(window).width() > 768) {
        $('.two-sliders .slider-wrapper-swiper').hover(function () {
            $('.cases-slider .hover').addClass('active')
        }, function () {
            $('.two-sliders .hover').removeClass('active')
            $('.two-sliders .hover').removeAttr('style');
        });

        $('.two-sliders .slider-wrapper-swiper').mousemove(function (e) {
            let top = e.clientY;
            let left = e.clientX;

            $('.two-sliders .hover').css('top', top);
            $('.two-sliders .hover').css('left', left);
        });
    }
}
