export default function() {

    splitLines();

    function splitLines() {
        $('.text-wrapper p').each(function() {
            $(this).splitLines({
                tag: '<div class="line">',
                keepHtml: true,
                addMask: true
            });
        });
    }
}
