import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export default function() {
    if(document.querySelector('.reviews-slider') === null) {
        return;
    }

    const swiperProjects = new Swiper('.reviews-swiper', {
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: true,
        loop: true,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: ".review-swiper-button-next-mobile",
            prevEl: ".review-swiper-button-prev-mobile",
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 3.55,
                spaceBetween: 48,
                navigation: {
                    nextEl: ".review-swiper-button-next",
                    prevEl: ".review-swiper-button-prev",
                },
            }
        }
    });
}

