//Import component JS below here

import AOS from 'aos';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
//gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
import AirDatepicker from 'air-datepicker';
import localeNL from 'air-datepicker/locale/nl';

import headerInit from '../components/header/header';
import menuInit from '../components/header-with-submenus/header-with-submenu';
import footerInit from '../components/footer/footer';
import homepageHeaderInit from '../components/1-homepage-header/1-homepage-header';
import lineMaskInit from '../components/4-centered-text-with-image/4-centered-text-with-image';
import reviewsSliderInit from '../components/8-reviews-slider/8-reviews-slider';
import imageGalleryInit from '../components/11-image-gallery/11-image-gallery';
import twoSlidersInit from '../components/12-two-sliders/12-two-sliders';
import stackingCardsInit from '../components/17-stacking-cards/17-stacking-cards';
import videoBlockInit from '../components/18-video-block/18-video-block';
import stickyNavInit from '../components/sticky-navigation/sticky-navigation';
import verticalSliderInit from '../components/21-vertical-slider/21-vertical-slider';
import ctaHoverInit from '../components/3-fullscreen-ctas/3-fullscreen-ctas';
import projectHoverInit from '../components/7-projects-overview/7-projects-overview';

$(document).foundation();

$(document).ready(function () {
    headerInit();
    menuInit();
    footerInit();
    homepageHeaderInit();
    lineMaskInit();
    reviewsSliderInit();
    imageGalleryInit();
    twoSlidersInit();
    stackingCardsInit();
    videoBlockInit();
    stickyNavInit();
    verticalSliderInit();
    ctaHoverInit();
    projectHoverInit();

    AOS.init({
        offset: 400, // offset (in px) from the original trigger point
        duration: 600, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true
    });

    $(".form-wrapper select").change(function () {
        if($(this).val() == "") $(this).addClass("empty");
        else $(this).removeClass("empty")
    });
    $(".form-wrapper select").change();

    $(function () {
        const textToAnimate = $('.animate-text');

        textToAnimate.each(function() {
            $(this).splitLines({
                tag: '<div class="mask"><div class="line">',
                keepHtml: true
            });
        })
    });

    // Trigger to animate headers
    if($('.animate-text').length) {
        const animateText = document.querySelectorAll(".animate-text");
        animateText.forEach((section) => {
            gsap.to(section, {
                scrollTrigger: {
                    trigger: section,
                    markers: false,
                    toggleClass: 'animate',
                    start: "bottom 90%",
                    once: true
                },
                opacity: 1,
            });
        });
    }

    // Trigger for image curtain
    if($('.image-wrapper').length) {
        const imageCurtains = document.querySelectorAll(".image-wrapper.curtain");
        imageCurtains.forEach((section) => {
            gsap.to(section, {
                scrollTrigger: {
                    trigger: section,
                    markers: false,
                    toggleClass: 'up',
                    start: "top 50%",
                    once: true
                },
                opacity: 1,
            });
        });
    }

    // Trigger for animating lines of text
    if($('.text-wrapper').length) {
        const lineMasks = document.querySelectorAll(".line-mask");
        lineMasks.forEach((section) => {
            gsap.to(section, {
                scrollTrigger: {
                    trigger: section,
                    markers: false,
                    toggleClass: 'up',
                    start: "bottom 70%",
                    end: "+=60",
                    scrub: true,
                },
                width: 0,
            });
        });
    }

    // Code for image parallax
    if($('.parallax-block').length) {
        $(function () {
            const parabox = $('.parallax-block'),
                $window = $(window);

            $window.scroll(function () {
                const scrollTop2 = $(window).scrollTop(),
                    elementOffset = parabox.offset().top,
                    distance = (elementOffset - scrollTop2),
                    scrollspeed = parseInt(parabox.data('scroll-speed')),
                    val = distance / scrollspeed;

                parabox.css('transform', 'translateY(' + val + 'px)');
            });
        });
    }

    // Code for image parallax
    if($('#contact-details').length) {
        $(function () {
            let tl = gsap.timeline({
                // yes, we can add it to an entire timeline!
                scrollTrigger: {
                    trigger: "#contact-details",
                    start: "bottom 95%",
                    markers: false,
                    toggleClass: "animate",
                }
            });

            tl.to(".contact-details-image", {opacity: 1, duration: .5});
            tl.to(".contact-details-body", {opacity: 1, translateX: 0, duration: .5});
            tl.to(".form-wrapper", {opacity: 1, translateY: 0, duration: .5});
        });
    }

    if($('#magazine-header').length) {
        $(function () {
            let tl = gsap.timeline({
                // yes, we can add it to an entire timeline!
                scrollTrigger: {
                    trigger: "#magazine-header",
                    start: "top 1px",
                    markers: false,
                    //toggleClass: "curtain-up",
                }
            });

            tl.to(".contact-header.magazine .content", {opacity: 1, translateY: 0, duration: .5});
            tl.to(".image-wrapper.curtain", {className: "image-wrapper curtain up", duration: .5});
            tl.to(".contact-header.magazine .magazine-images .cover-image", {opacity: 1, translateY: 0, duration: .5});
            tl.to(".contact-header.magazine .content-form", {opacity: 1, translateY: 0, duration: .5});
        });
    }

    if($('.header-with-title-breadcrumb-text').length) {
        $(function () {
            let tl = gsap.timeline({
                // yes, we can add it to an entire timeline!
                scrollTrigger: {
                    trigger: ".header-with-title-breadcrumb-text .breadcrumbs",
                    start: "bottom 100%",
                    markers: false,
                    //toggleClass: "animate",
                }
            });

            tl.to(".header-with-title-breadcrumb-text h1", {opacity: 1, duration: .5, translateY: 0, delay: 1});
            tl.to(".header-with-title-breadcrumb-text .content", {opacity: 1, translateY: 0, duration: .5});
            tl.to(".header-with-title-breadcrumb-text .breadcrumbs", {opacity: 1, translateY: 0, duration: .5});
            tl.to(".header-with-title-breadcrumb-text .sticky-navigation", {opacity: 1, translateY: 0, duration: .5});
        });
    }

    if($('.scroll-zoom-image').length) {
        $(function () {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".scroll-zoom-image",
                    start: "bottom 90%",
                    scrub: .1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                    markers: false,
                }
            });

            //tl.to(".scroll-zoom-image img", {scale: 1.2, duration: .1,});
            tl.to(".scroll-zoom-image .image-wrapper", {marginLeft: 0, marginRight: 0, duration: .1,});
        });
    }

    if(window.innerWidth > 1024) {
        if($('.our-team').length) {
            gsap.fromTo('.team-column-2', {
                //y: '-50',
                marginTop: '50'
            }, {
                //y: '+50',
                marginTop: '-50',
                scrollTrigger: {
                    start: 'top 50%',
                    end: 'top -100px',
                    scrub: true,
                    trigger: '.team-column-2',
                    markers: false,
                }
            });

            gsap.fromTo('.team-column-3', {
                //y: '-100',
                marginTop: '100',
            }, {
                //y: '+100',
                marginTop: '-100',
                scrollTrigger: {
                    start: 'top 50%',
                    end: 'top -100px',
                    scrub: .1,
                    trigger: '.team-column-3',
                    markers: false
                }
            });
        }
    }

    if($('.sticky-navigation').length) {
        $(function () {
            gsap.to('.sticky-navigation', {
                scrollTrigger: {
                    trigger: '.sticky-navigation',
                    markers: false,
                    toggleClass: 'sticky',
                    start: "bottom 20%",
                    scrub: true,
                    endTrigger: ".contact-form",
                    end: "bottom top"
                }
            });
        });
    }

    if($('.date-picker').length) {
        const datePickers = $('.date-picker');

        datePickers.each(function() {
            new AirDatepicker($(this)[0], {
                locale: localeNL
            })
        });
    }

    /*if($('.fullscreen-header').length) {
        const tl = gsap.timeline({repeat: 0, delay: .5});
        tl.to(".fullscreen-header h1", {opacity: 1, translateY: 0, duration: .5});
        tl.to(".fullscreen-header .breadcrumbs", {opacity: 1, translateY: 0, duration: .5});
    }*/
});
