import Splide from "@splidejs/splide";

export default function() {
    if(document.querySelector('.vertical-slider') === null) {
        return;
    }

    let sliderDirection = 'ttb';
    let sliderHeight = '1100px';

    if(window.innerWidth < 1025) {
        sliderDirection = 'ltr';
        let sliderHeight = '800';
    }

    const splideMain = new Splide('.vertical-slider-splide', {
        arrows: false,
        pagination: false,
        autoWidth: true,
        gap: 0,
        focus: 'center',
        //direction: 'ttb',
        direction: sliderDirection,
        height: sliderHeight,
    });

    const splideNav = new Splide('.vertical-slider-nav-splide', {
        width: 200,
        height: 224,
        //direction: 'ttb',
        direction: sliderDirection,
        isNavigation: true,
        fixedWidth: 200,
        fixedHeight: 39,
        pagination: false,
        cover: false,
        focus: "center",
        //trimSpace: false,
    });

    const splideContent = new Splide('.vertical-slider-content', {
        arrows: false,
        pagination: false,
        //autoplay: true,
        autoWidth: true,
        gap: 0,
        //direction: 'ttb',
        direction: sliderDirection,
        height   : '340px',
    });

    splideMain.sync(splideNav).sync(splideContent).mount();
    splideNav.mount();
    splideContent.mount();
}
