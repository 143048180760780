export default function() {
    if($('.has-children').length) {

        $('.mega-menu').mouseenter(function() {
            $('.header').toggleClass('header-light');
            $(this).parent().toggleClass('remove-pos');
        }).mouseleave(function() {
            $('.header').toggleClass('header-light');
            $(this).parent().toggleClass('remove-pos');
        });

    }
}
