export default function() {
    $(function() {
        $('.header__menutrigger').click(function(){
            $('.header').toggleClass('menu-open');
        });
    });

    if($('.header__sidemenu').length) {
        $('.header__sidemenu .menu-item.has-children > a').on("click", function (e) {
            e.preventDefault();

            $(e.target).next('.submenu').addClass('open');
        });

        $('.submenu-back-btn').on("click", function(e) {
            $(e.target).closest('.submenu').removeClass('open');
        });

        if( $(window).width() < 1024 ) {
            $(window).click(function(e) {
                if(e.target.classList[0] === "header__nav" || e.target.classList[0] === "header" || e.target.classList[0] === "header__top") {
                    $('.header').toggleClass('menu-open');
                }
            });
        }
    }
}
