export default function() {

    if (document.querySelector('.sticky-navigation') === null) {
        return;
    }

    const slugify = str => {
        return str.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
    }

    const url = location.protocol + '//' + location.host + location.pathname;
    const arrItems = document.querySelectorAll('[data-sticky-title]');
    const arrElements = [];
    arrItems.forEach((node) => {
        let title = node.getAttribute('data-sticky-title');
        let id = node.id = slugify(title);
        node.setAttribute('data-magellan-target', id);
        arrElements.push(`<li><a href="${url}#${id}">${title}</a></li>`)
    });

    document.querySelector('.sticky-navigation ul').innerHTML = arrElements.join('');
    document.querySelector('.sticky-navigation ul li:first-child').classList.add("active"); // Add active class to first child









    $(function() {
        $('.sticky-navigation li').click(function(e) {
            //e.preventDefault();
            $('.sticky-navigation li').removeClass('active');
            $(this).addClass('active');
        });

        // Don't execute on mobile.
        if($(window).width() >= 768) {
            $('section[data-sticky-title]').each(function () {
                let elem = $(this);
                window.addEventListener('scroll', function () {
                    let position = elem[0].getBoundingClientRect();

                    if (position.top + 200 < window.innerHeight && position.bottom >= 0) {
                        $('.sticky-navigation li').removeClass('active');
                        $('.sticky-navigation li').each(function () {
                            let activeLi = $(this).find('a').attr("href").substr($(this).find('a').attr("href").indexOf("#") + 1);
                            if (activeLi === elem.attr("id")) {
                                $(this).addClass('active');
                            }
                        });
                    }
                });
            });
        }
    });

}
